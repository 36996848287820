@import "./utils/utils";

// Colors

$white: #ffffff;
$black: #0e0e10;

$hoverColor: #f5f5f5;
$hoverColorDark: #ededed;

$g300: #c8e6c9;
$g500: #256029;

$r200: #f5dad3;
$r300: #f2c1b6;
$r500: #ce6c62;
$r700: #8c313d;
$r900: #501221;

$reds: (
  "r200": $r200,
  "r300": $r300,
  "r500": $r500,
  "r700": $r700,
  "r900": $r900
);

$grees: (
  "g300": $g300,
  "g500": $g500
);

$color-map: merge-many($reds);
