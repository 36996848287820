.app-login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .app-login-form {
    width: 500px;

    .login-form-identifier {
      width: 100%;
    }

    .reset-form-identifier {
      width: 100%;
      margin-bottom: 1rem;
    }

    .login-form-password {
      div {
        width: 100%;
      }
    }

    .reset-form-password {
      // margin-bottom: 1rem;
      input {
        width: 100% !important;
      }
    }

    .login-form-reset-text {
      margin-top: 1rem;
      .reset-button {
        all: unset;
        font-weight: 600;
        font-size: 14px;
        color: var(--primary-color);

        &:hover {
          color: var(--primary-light);
          cursor: pointer;
        }
      }
    }

    .sign-in-btn {
      margin-top: 1rem;
    }
  }

  @media (max-width: 575px) {
    .app-login-form {
      width: 90%;
      padding: 2rem;
    }
  }
}

.reset-button {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}

.reset-password-timer {
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  color: var(--primary-color);
  margin-bottom: 1rem;

  .timer-text {
    font-size: 12px;
    color: var(--black);
  }
}
