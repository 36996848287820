.user-badge {
  // border-radius: var(--border-radius);
  // padding: 0.25em 0.5rem;
  // text-transform: uppercase;
  // font-weight: 700;
  // font-size: 12px;
  // letter-spacing: 0.3px;
  display: flex;
  justify-content: center;
  &.status-active {
    // background: $g300;
    color: var(--green-500);
  }

  &.status-inactive {
    // background: $r300;
    color: var(--red-500);
  }
}

.order-status {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-draft {
    background: $g300;
    color: $g500;
  }

  &.status-rejected {
    background: $r300;
    color: $r700;
  }

  &.status-new {
    background: var(--yellow-600);
    color: var(--yellow-50);
  }

  &.status-pending {
    background: var(--yellow-500);
    color: var(--white);
  }

  &.status-completed {
    background: var(--cyan-500);
    color: var(--white);
  }

  &.status-prepare_to_shipment {
    background: var(--bluegray-500);
    color: var(--white);
  }

  &.status-delivered {
    background: var(--primary-color);
    color: var(--white);
  }

  &.status-shipped {
    background: var(--cyan-600);
    color: var(--white);
  }

  &.status-inactive {
    background: $r300;
    color: $r700;
  }
}
