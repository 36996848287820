@each $key, $color in $color-map {
  .gr-#{$key} {
    color: $color !important;
    fill: $color !important;
  }
}

@each $key, $value in $color-map {
  .bg-#{$key} {
    background-color: $value !important;
    color: color-contrast($value, $dark: change-color($value, $lightness: 10%));
  }
}

.fit-content {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.action-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: fit-content;
}
.icon-action-button {
  padding: 0.5rem;
  border-radius: 50%;
  &:hover {
    background-color: $hoverColorDark;
    cursor: pointer;
  }
}

.p-datatable-tbody {
  tr {
    &:hover {
      background-color: $hoverColor;
      cursor: pointer;
    }
  }
}

.accordion-radio-group {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  h6 {
    margin-bottom: 0.5rem;
    margin-right: 30px;
  }
  .radio-group-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .p-field-radiobutton {
      margin-bottom: 0.5rem;
      margin-right: 20px;
      .p-radiobutton {
        margin-right: 5px;
      }
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    h6 {
      margin-bottom: 0.75rem;
    }
  }
}

.group-role-radio-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .p-field-radiobutton {
    margin-bottom: 0.5rem;
    margin-right: 20px;
    .p-radiobutton {
      margin-right: 5px;
    }
  }
}

.radio_wrapper {
  gap: 0.5 8;
}

.tab-menu-full {
  .p-tabmenu-nav {
    width: 100%;
    & > * {
      width: 50%;

      .p-menuitem-link {
        display: flex;
        justify-content: center;
        align-items: center;

        .p-menuitem-icon {
          margin-right: 0.75rem;
        }
      }
    }
  }
}

.main-loader {
  // position: fixed;
  // left: 0;
  // top: 0;
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.custom-pagination-contaiener {
  padding: 0.5rem !important;
  display: flex;
  align-items: center;

  .rows-per-page {
    display: flex;
    align-items: center;
    margin-right: 2rem;

    .rows-per-page-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.43;
      margin-right: 0.75rem;
    }
  }

  .pagination-action-button {
    all: unset;
  }
}

.pagination-dropdown {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: var(--gray-100);
    border-radius: 8px;
  }
  .p-dropdown-label {
    font-size: 0.875rem;
    font-weight: 500;
  }
  .p-dropdown-trigger {
    svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
}

.p-dropdown-items {
  padding: 0 !important;
  li {
    padding: 0.5rem 1rem;
  }
}

.profile-menu {
  padding: 0;
}

.pagination-rows-per-page {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-color);
  user-select: none;
}

.pagination-current-page-report {
  font-size: 0.875rem;
  font-weight: 500;
  user-select: none;
  color: var(--text-color);
  width: "120px";
  text-align: "center";
}

.p-frozen-column {
  position: sticky;
  // z-index: 1;
  background-color: var(--gray-50);
  right: 0;
  width: 20px !important;
}

.card {
  border-radius: 4px !important;
}

// .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
//   padding: 0.5rem !important;
// }

.default-data-table .p-datatable .p-datatable-tbody > tr > td {
  height: 20px !important;
}
.dealers-data-table, .no-footer-datatable {
  .p-datatable-wrapper {
    border-radius: 4px !important;
  }
}

.p-datatable-wrapper {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.p-paginator-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-datatable-footer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0.5rem;
  // display: none;
}

.password-w-100 {
  width: 100% !important;
  input {
    width: 100% !important;
  }
}

.p-multiselect-sm {
  height: 38px !important;
  .p-multiselect-label-container {
    .p-multiselect-label {
      padding: 0;
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
    }
  }
}

.p-treeselect-sm {
  height: 38px !important;
  .p-treeselect-label-container {
    .p-treeselect-label {
      padding: 0;
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
    }
  }
}
.p-multiselect-items {
  padding: 0;
}
.p-multiselect-header {
  padding: 0.5rem;
  .p-inputtext {
    height: 32px;
  }
}

.create-update-dialog-lg {
  width: 75vw;
}

.create-update-dialog {
  width: 60vw;
}

.create-update-dialog-sm {
  width: 40vw;
}

@media (max-width: 992px) {
  .create-update-dialog-lg {
    width: 80vw;
  }
  .create-update-dialog {
    width: 75vw;
  }
  .create-update-dialog-sm {
    width: 60vw;
  }
}

@media (max-width: 640px) {
  .create-update-dialog-lg {
    width: 95vw;
  }
  .create-update-dialog {
    width: 90vw;
  }
  .create-update-dialog-sm {
    width: 90vw;
  }
}

.welcome-page {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orders-product-col {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  img {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 4px;
  }
}

.products-product-col {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  img {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 4px;
    object-fit: cover;
  }
}

.dialog-orders-product-col {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  img {
    width: 72px;
    height: 72px;
    min-width: 72px;
    min-height: 72px;
    border-radius: 4px;
  }
}

.p-column-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-icons-default {
    display: flex;
    flex-direction: column;

    .pi-sort-up-fill {
      margin-bottom: -2px;
      color: var(--gray-400);
    }
    .pi-sort-down-fill {
      margin-top: -2px;
      color: var(--gray-400);
    }
  }

  .header-icons-default-down {
    display: flex;
    flex-direction: column;

    .pi-sort-up-fill {
      margin-bottom: -2px;
      color: var(--primary-lighter);
    }
    .pi-sort-down-fill {
      margin-top: -2px;
      color: var(--primary-color);
    }
  }

  .header-icons-default-up {
    display: flex;
    flex-direction: column;

    .pi-sort-up-fill {
      margin-bottom: -2px;
      color: var(--primary-color);
    }
    .pi-sort-down-fill {
      margin-top: -2px;
      color: var(--primary-lighter);
    }
  }
}

.quantity-input {
  all: unset;
}

.disabled-expander {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.upload-image-div {
  border: 1px dashed var(--gray-300);
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  z-index: 2000;
  &:hover {
    border: 1px dashed #1677ff;
  }

  .CircularProgressbar {
    width: 60px !important;

    .CircularProgressbar-path {
      stroke: var(--primary-color);
    }
    .CircularProgressbar-text {
      fill: var(--primary-color);
    }
  }
}

.upload-icon-plus {
  background-color: var(--gray-400);
  padding: 5px;
  margin: 5px;
  border-radius: 50%;
  margin-bottom: 2px;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: var(--gray-300);
  }
}
.upload-icon-trash {
  background-color: var(--red-400);
  padding: 5px;
  margin: 5px;
  border-radius: 50%;
  margin-bottom: 2px;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: var(--red-300);
  }
}

.uploader-upload-file {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px dashed var(--gray-300);
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  width: 100px;
  height: 100px;
  cursor: pointer;
  transition: 0.2s;
  z-index: 2000;
}

.uploader-main-div {
  display: flex;
  padding: 1px;
  margin-right: 0.25rem;
}

.preview {
  border: 1px solid #d9d9d9;
  position: relative;
  padding: 5px;
  width: 100px;
  height: 100px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.25rem;

  .action_buttons_container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  &:hover {
    cursor: pointer;
    .action_buttons_container {
      background-color: rgba(0, 0, 0, 0.2);
      display: flex !important;
    }
  }
  img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 4px;
  }
}

.preview_error {
  border: 1px solid #ff4d4f !important;
}
.preview_error_icon {
  width: 90px;
  height: 90px;
  color: #ff4d4f;
}
.preview_file_icon {
  width: 90px;
  height: 90px;
  color: var(--gray-400);
}

.image_viewer {
  .react-viewer-image {
    object-fit: contain;
    aspect-ratio: 1/1;
  }

  .react-viewer-mask {
    z-index: 4350 !important;
  }

  .react-viewer-canvas {
    z-index: 4500 !important;
  }

  .react-viewer-footer {
    height: calc(100vh / 6);
    z-index: 4500 !important;
  }

  .react-viewer-toolbar {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-viewer-close {
    margin-right: 0 !important;
    // position: relative;
    width: 60px !important;
    height: 60px !important;
    border-radius: 0 0 0 5px;
    top: 0;
    right: 0;
    z-index: 4550 !important;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &:before {
        font-size: 17px;
      }
    }
  }

  .react-viewer-btn {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }

  .react-viewer-icon {
    margin: auto;

    &:before {
      font-size: 17px;
    }
  }
}

.img_icon_flipped {
  transform: scaleX(-1);
  font-size: 20;
}
.img_icon {
  font-size: 20;
}

.p-dialog-mask {
  z-index: 1000 !important;
}

.small-switch {
  transform: scale(0.7); /* Adjust scale for smaller size */
}

.p-selection-column {
  text-align: center;
}


.p-column-filter-row {
  // padding: 0.25rem !important;
  height: 20px;
  div {
    input {
      height: 30px;
    }
  }
}

.p-datatable-thead{
  tr{
    th {
      padding: 0.5rem !important;
    }
  }
}

.message-sm {
  padding: 0.5rem 0.75rem !important;

  svg {
    width: 14px;
  }

  .p-inline-message-text {
    font-size: 0.875rem;
    font-weight: 500;
    user-select: none;
  }
}

.table-title {
  font-size: 18px;
  font-weight: 600;
  margin-right: 0.5rem;
}

.p-column-filter-clear-button {
  display: none !important;
}

label.required::after {
  content: "*";
}
.saving-btn {
  height: 40px !important;
}

.loading-button {
  display: flex;
  align-items: center;
  height: fit-content;
}

.limit-info-card {
  .limit-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--gray-400);
    border-radius: 4px;
    padding: 1rem;
    // margin-top: 1rem;

    .limit-info-card-number-and-text {
      .limit-info-card-number {
        font-size: 2rem;
        font-weight: 500;
      }

      .limit-info-card-text {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .limit-info-icon {
      font-size: 3rem;
    }
  }
}